<template>
	<section>
		<CRow>
			<CCol lg="12" class="d-flex justify-content-between align-items-baseline mb-3">
				<h4 class="m-0 date-info">
					Schedule
				</h4>

				<div class="limit-product-period-status">
					<CBadge class="badge-period-status" :color="LIMIT_PRODUCT_COLOR.PERIODS_STATUSES[period]">
						{{ LIMIT_PRODUCT_TITLE.PERIODS_STATUSES[period] }}
					</CBadge>
				</div>
			</CCol>
		</CRow>

		<CRow>
			<CCol lg="12" class="mb-4">
				<p class="m-0 date-desc typo-body-2">
					The period to products in this campaign.
				</p>
			</CCol>
		</CRow>

		<CRow class="mb-4">
			<CCol md="6">
				<BaseInputDate
					v-model="$v.startDateValue.$model"
					:is-valid="!$v.startDateValue.$error"
					:label="labelStartDate"
					invalid-feedback="Start date is required"
					placeholder="Select date"
					@input="handleActiveStartDateChange"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
			</CCol>
			<CCol v-if="false" md="2">
				<BaseInputTime
					v-model="$v.startTimeValue.$model"
					:label="labelStartTime"
					:is-valid="!$v.startTimeValue.$error"
					:invalid-feedback="errorStartTime"
				/>
			</CCol>

			<CCol md="6">
				<BaseInputDate
					v-model="endDateValue"
					:disabled="!$v.startDateValue.$model || $v.startDateValue.$invalid"
					:min-date="$v.startDateValue.$model"
					label="End date"
					placeholder="Select date"
					data-test-id="end-date"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
			</CCol>
			<CCol v-if="false" md="2">
				<BaseInputTime
					v-model="$v.endTimeValue.$model"
					:disabled="!$v.startDateValue.$model || $v.startDateValue.$invalid || !endDateValue"
					:is-valid="!$v.endTimeValue.$error"
					:invalid-feedback="errorEndTime"
					label="End time"
				/>
			</CCol>
		</CRow>

		<CRow>
			<CCol lg="12">
				<label for="display-name">Duration<span> (optional)</span></label>
				<CInput
					v-model.trim="durationTimeValue"
					placeholder="1"
					type="number"
					data-test-id="input-duration-time"
					style="margin-bottom: 0;"
				/>
			</CCol>
		</CRow>
	</section>
</template>

<script>
import BaseInputDate from '@/components/BaseInputDate.vue';
import BaseInputTime from '@/components/BaseInputTime.vue';
import dayjs from 'dayjs';
import { requiredIf } from 'vuelidate/lib/validators';
import { LIMIT_PRODUCT_COLOR, LIMIT_PRODUCT_TITLE } from '../../enums/limitProduct';

export default {
	name: 'LimitProductDate',
	components: {
		BaseInputDate,
		BaseInputTime,
	},
	props: {
		period: {
			type: String,
			default: null,
		},
		status: {
			type: Boolean,
			default: null,
		},
		startDate: {
			type: [Date, String],
			default: null,
		},
		startTime: {
			type: [String, Number],
			default: null,
		},
		endDate: {
			type: [Date, String],
			default: null,
		},
		endTime: {
			type: [String, Number],
			default: null,
		},
		durationTime: {
			type: [String, Number],
			default: 0,
		},
	},
	validations() {
		return {
			startDateValue: {
				required: requiredIf((data) => data.status),
			},
			startTimeValue: {
				required: requiredIf((data) => data.status),
				validTime(time) {
					return time.length === 5;
				},
			},
			endTimeValue: {
				validTime(time) {
					return time.length === 5 || time.length === 0;
				},
			},
		};
	},
	data() {
		return {
			LIMIT_PRODUCT_COLOR,
			LIMIT_PRODUCT_TITLE,
		};
	},
	computed: {
		startDateValue: {
			get() {
				return this.startDate;
			},
			set(value) {
				this.$emit('update:startDate', value);
			},
		},

		startTimeValue: {
			get() {
				return this.startTime;
			},
			set(value) {
				this.$emit('update:startTime', value);
			},
		},

		endDateValue: {
			get() {
				return this.endDate;
			},
			set(value) {
				this.$emit('update:endDate', value);
			},
		},

		endTimeValue: {
			get() {
				return this.endTime;
			},
			set(value) {
				this.$emit('update:endTime', value);
			},
		},

		labelStartDate() {
			return this.status ? 'Select date*' : 'Select date';
		},

		labelStartTime() {
			return this.status ? 'Select time*' : 'Select time';
		},

		errorStartTime() {
			if (!this.startTimeValue.required) {
				return 'Required field';
			}

			if (!this.startTimeValue.validTime) {
				return 'Time not valid';
			}

			return null;
		},

		errorEndTime() {
			if (!this.endTimeValue.validTime) {
				return 'Time not valid';
			}

			if (!this.endTimeValue.after) {
				return `Should be after ${this.startTimeValue}`;
			}

			return null;
		},

		durationTimeValue: {
			get() {
				return this.durationTime;
			},
			set(value) {
				this.$emit('update:durationTime', Number(value));
			},
		},
	},
	methods: {
		// TODO: get it from action after integrated
		clearError() {},
		handleActiveStartDateChange(value) {
			if (
				!this.endDateValue ||
				dayjs(value).isAfter(dayjs(this.endDateValue))
			) {
				this.endDateValue = value;
			}
		},
	},
};
</script>
