<template>
	<section>
		<CRow>
			<CCol lg="12" class="d-flex justify-content-between align-items-baseline mb-3">
				<h4 class="m-0 general-info">
					General info
				</h4>

				<div class="limit-product-id">
					ID: <strong>{{ limitProductId }}</strong>
				</div>
			</CCol>
		</CRow>

		<CRow>
			<CCol lg="12">
				<label for="display-name">Limit product name<span class="danger">*</span></label>
				<CInput
					v-model.trim="$v.nameValue.$model"
					:is-valid="!$v.nameValue.$error && null"
					:invalid-feedback="$t('global.error.required')"
					placeholder="Limit product name"
					type="text"
					data-test-id="input-name"
				/>
			</CCol>
		</CRow>

		<CRow>
			<CCol xl="12" class="d-flex align-items-center">
				<label class="toggle-label" for="display-status">Status</label>
				<CSwitch
					id="display-status"
					:checked="status"
					variant="3d"
					size="sm"
					class="switch-custom toggle-status"
					color="success"
					@update:checked="handleUpdateStatus"
				/>
				<span class="typo-body-2">{{ statusText }}</span>
			</CCol>
		</CRow>
	</section>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { STATUS as mappingStatus } from '../../mapping/banner';

export default {
	name: 'LimitProductGeneral',
	props: {
		id: {
			type: Number,
			default: 0,
		},
		name: {
			type: String,
			default: '',
		},
		status: {
			type: Boolean,
			default: false,
		},
	},
	validations() {
		return {
			nameValue: {
				required,
			},
		};
	},
	computed: {
		limitProductId() {
			return this.id ? this.id : 'Generate after saving';
		},

		nameValue: {
			get() {
				return this.name;
			},
			set(value) {
				this.$emit('update:name', value);
			},
		},

		statusText() {
			const text = mappingStatus[this.status] || '';
			return this.$t(text);
		},
	},
	methods: {
		// TODO: get it from action after integrated
		clearError() {},
		handleUpdateStatus(value) {
			this.$emit('update:status', value);

			this.clearError();
		},
	},
};
</script>

<style lang="scss" scoped>
.general-info {
	flex: 1;
}

.limit-product-id {
	font-size: rem(12);
	color: $color-black-45;
}

.toggle {
	&-label {
		width: 54px;
		margin: 0 24px 0 0;
	}

	&-status {
		margin-right: 12px;
	}
}
</style>
