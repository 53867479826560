<template>
	<section>
		<CRow>
			<CCol lg="12" class="d-flex justify-content-between align-items-baseline mb-3">
				<h4 class="m-0 quantity-info">
					Limit Product per Account
				</h4>
			</CCol>
		</CRow>

		<CRow>
			<CCol lg="12" class="mb-4">
				<p class="m-0 quantity-desc typo-body-2">
					The information will save immediately after click “Done” in modal, It will take a few minutes to sync it. Please carefully managing.
				</p>
			</CCol>
		</CRow>

		<CRow>
			<CCol lg="12">
				<label for="display-name">Quantity<span class="danger">*</span></label>
				<CInput
					v-model.trim="$v.quantityValue.$model"
					:is-valid="!$v.quantityValue.$error && null"
					:invalid-feedback="$t('global.error.required')"
					placeholder="1"
					type="number"
					data-test-id="input-quantity"
					style="margin-bottom: 0;"
				/>
			</CCol>
		</CRow>
	</section>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
	name: 'LimitProductQuantity',
	props: {
		quantity: {
			type: [String, Number],
			default: '',
		},
	},
	validations() {
		return {
			quantityValue: {
				required,
			},
		};
	},
	computed: {
		quantityValue: {
			get() {
				return this.quantity;
			},
			set(value) {
				this.$emit('update:quantity', Number(value));
			},
		},
	},
	methods: {
		// TODO: get it from action after integrated
		clearError() {},
	},
};
</script>
