<template>
	<section>
		<CRow>
			<CCol lg="12" class="d-flex justify-content-between align-items-baseline mb-3">
				<h4 class="m-0 customer-info">
					Customer group
				</h4>
			</CCol>
		</CRow>

		<CRow>
			<CCol lg="12" class="d-flex justify-content-between align-items-center mb-3">
				<label class="mb-0">
					Customer group<span class="danger">*</span>
				</label>

				<CButton
					type="button"
					class="btn btn-add"
					color="secondary"
					data-test-id="button-add"
					@click="$refs['modal-customer-group'].open()"
				>
					Add customer group
				</CButton>
			</CCol>
		</CRow>

		<BaseNoItemBanner
			v-if="!customerGroups.length"
			text="There is no customer group added."
			:class="['mb-3', { 'is-invalid': $v.$error }]"
		/>
		<ul v-else class="customer-group-list">
			<li v-for="item in customerGroups" :key="item.id" class="list-item typo-body-2">
				{{ item.name }}
			</li>
		</ul>

		<ModalCustomerGroup
			ref="modal-customer-group"
			:title="customerGroupTitle"
			:list="customerGroups"
			@onConfirm="handleCustomerGroupListChange"
		/>
	</section>
</template>

<script>
import ModalCustomerGroup from '@/components/ModalCustomerGroup.vue';
import { minLength, required } from 'vuelidate/lib/validators';

export default {
	name: 'LimitProductCustomerGroup',
	components: {
		ModalCustomerGroup,
	},
	props: {
		customerGroups: {
			type: Array,
			default: () => [],
		},
	},
	validations() {
		return {
			customerGroups: {
				required,
				minLength: minLength(1),
			},
		};
	},
	computed: {
		customerGroupTitle() {
			return `${this.customerGroups && this.customerGroups.length ? 'Edit' : 'Add'} customer group`;
		},
	},
	methods: {
		handleCustomerGroupListChange(list) {
			let transformList = [];
			if (list && Array.isArray(list)) {
				transformList = list.map(({ id, name }) => ({ id, name }));
			}
			this.$emit('update:customerGroups', transformList);
		},
	},
};
</script>

<style lang="scss" scoped>
.customer-group-list {
	overflow-y: auto;
	min-height: 60px;
	max-height: 468px;
	padding: 20px 16px 8px;
	border-radius: 4px;
	border: 1px solid $color-gray-400;
	list-style: none;

	.list-item {
		border-radius: 4px;
		background-color: $color-gray-300;
		color: $color-black-90;
		display: inline-block;
		padding: 0 6px;
		margin-right: 12px;
		margin-bottom: 12px;
	}
}

.is-invalid {
	border: 1px solid $color-alert;
}
</style>
