<template>
	<section>
		<CRow>
			<CCol lg="12" class="d-flex justify-content-between align-items-baseline mb-3">
				<h4 class="m-0 customer-info">
					Specific SKUs
				</h4>
			</CCol>
		</CRow>

		<CRow>
			<CCol lg="12" class="d-flex justify-content-between align-items-center mb-3">
				<label class="mb-0">
					Specific SKUs<span class="danger">*</span>
				</label>

				<CButton
					type="button"
					class="btn btn-add"
					color="secondary"
					data-test-id="button-add"
					@click="$refs['modal-sku-list'].open()"
				>
					Add SKUs
				</CButton>
			</CCol>
		</CRow>

		<CRow>
			<CCol lg="12" class="mb-4">
				<p class="m-0 quantity-desc typo-body-2">
					The information will save immediately after click “Done” in modal, It will take a few minutes to sync it. Please carefully managing.
				</p>
			</CCol>
		</CRow>

		<BaseNoItemBanner
			v-if="!skus.length"
			text="There is no product(s) added."
			:class="['mb-3', { 'is-invalid': $v.$error }]"
		/>
		<ul v-else class="product-list">
			<li v-for="(item, key) in skus" :key="key" class="list-item typo-body-2">
				{{ item }}
			</li>
		</ul>

		<ModalSkuList
			ref="modal-sku-list"
			:title="skuTitle"
			:sku-list="skus"
			@onConfirm="handleProductListChange"
		/>
	</section>
</template>

<script>
import ModalSkuList from '@/components/ModalSkuList.vue';
import { minLength, required } from 'vuelidate/lib/validators';

export default {
	name: 'LimitProductSpecificSku',
	components: {
		ModalSkuList,
	},
	props: {
		skus: {
			type: Array,
			default: () => [],
		},
	},
	validations() {
		return {
			skus: {
				required,
				minLength: minLength(1),
			},
		};
	},
	computed: {
		skuTitle() {
			return `${this.customerGroups && this.customerGroups.length ? 'Edit' : 'Add'} product`;
		},
	},
	methods: {
		handleProductListChange(list) {
			// no need to transform data
			this.$emit('update:skus', list);
		},
	},
};
</script>

<style lang="scss" scoped>
.product-list {
	overflow-y: auto;
	min-height: 60px;
	max-height: 468px;
	padding: 20px 16px 8px;
	border-radius: 4px;
	border: 1px solid $color-gray-400;
	list-style: none;

	.list-item {
		border-radius: 4px;
		background-color: $color-gray-300;
		color: $color-black-90;
		display: inline-block;
		padding: 0 6px;
		margin-right: 12px;
		margin-bottom: 12px;
	}
}

.is-invalid {
	border: 1px solid $color-alert;
}
</style>
